import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { ActivatedRoute, Router } from '@angular/router';
import { isEqual } from 'lodash';

import { DLT_DATA_TYPES } from '../../constants';
import { DataListTableDataElement } from '../../types/data-element';
import { DataListTableButtonDataElement } from '../../types/data-element/button-element';
import { DataListTableChipDataElement } from '../../types/data-element/chip-element';
import { DataListTableExpansionPanelElement } from '../../types/data-element/expansion-panel-element';
import { DataListTableInnerHTMLDataElement } from '../../types/data-element/inner-html-element';
import { DataListTableLinkDataElement } from '../../types/data-element/link-element';
import { DataListTablePlainDataElement } from '../../types/data-element/plain-element';
import { DataListTableProgressBarDataElement } from '../../types/data-element/progress-bar-element';
import { DataListTableSeparatorDataElement } from '../../types/data-element/separator-element';

interface TypedElement<T> {
  is: boolean;
  value?: T;
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'dlt-single-element',
  templateUrl: 'single-element.component.html',
  styleUrls: ['single-element.component.scss'],
})
export class SingleElementComponent implements OnChanges {
  @Input() item?: DataListTableDataElement;
  @Input() panel?: MatExpansionPanel;
  @Input() headerRow = false;
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onDataElementClickAction = new EventEmitter<string>();
  @Output() subExpansionPanelExpand = new EventEmitter<void>();
  @Output() expansionPanelActionElementClickAction = new EventEmitter<any>();

  typedElements = {
    [DLT_DATA_TYPES.BUTTON]: {
      is: false,
    } as TypedElement<DataListTableButtonDataElement>,
    [DLT_DATA_TYPES.CHIP]: {
      is: false,
    } as TypedElement<DataListTableChipDataElement>,
    [DLT_DATA_TYPES.INNER_HTML]: {
      is: false,
    } as TypedElement<DataListTableInnerHTMLDataElement>,
    [DLT_DATA_TYPES.LINK]: {
      is: false,
    } as TypedElement<DataListTableLinkDataElement>,
    [DLT_DATA_TYPES.PLAIN]: {
      is: false,
    } as TypedElement<DataListTablePlainDataElement>,
    [DLT_DATA_TYPES.SEPARATOR]: {
      is: false,
    } as TypedElement<DataListTableSeparatorDataElement>,
    [DLT_DATA_TYPES.PROGRESS_BAR]: {
      is: false,
    } as TypedElement<DataListTableProgressBarDataElement>,
    [DLT_DATA_TYPES.EXPANSION_PANEL]: {
      is: false,
    } as TypedElement<DataListTableExpansionPanelElement>,
  };

  progressBarColor = '';

  constructor(private router: Router, private route: ActivatedRoute) {}

  ngOnChanges(changes: SimpleChanges): void {
    const itemChanges = changes['item'];
    if (!itemChanges) return;

    const newItem: DataListTableDataElement = itemChanges?.currentValue;
    if (isEqual(newItem, itemChanges.previousValue)) return;

    Object.entries(this.typedElements).forEach(([key, value]) => {
      const typeMatched =
        key.toString() === (newItem.type || DLT_DATA_TYPES.PLAIN).toString();

      value.is = typeMatched;
      value.value = typeMatched ? newItem : undefined;
    });

    if (newItem.type === DLT_DATA_TYPES.PROGRESS_BAR) {
      if (
        !newItem.progress ||
        typeof newItem.progress !== 'number' ||
        newItem.progress < 0
      ) {
        newItem.progress = 0;
      }
      if (newItem.progress > 100) {
        newItem.progress = 100;
      }
      if (newItem && newItem.progress < 100) {
        this.progressBarColor = newItem.colorInProgress;
      } else {
        this.progressBarColor = newItem.colorCompleted;
      }
    }
  }

  navigateToLink() {
    if (
      !this.typedElements.link?.is ||
      !this.typedElements.link.value ||
      this.typedElements.link.value.linkType !== 'internal'
    )
      return;

    const { href, hrefParams } = this.typedElements.link.value;
    this.router.navigate([href], {
      queryParams: hrefParams,
      relativeTo: this.route,
    });
  }

  actionLinkTrigger() {
    if (
      !this.typedElements.link?.is ||
      !this.typedElements.link.value ||
      this.typedElements.link.value.linkType !== 'action'
    )
      return;

    this.onDataElementClickAction.emit(this.typedElements.link.value.action);
  }

  dataElementActionClicked() {
    if (!this.typedElements.button?.is || !this.typedElements.button.value)
      return;

    this.onDataElementClickAction.emit(this.typedElements.button.value.action);
  }

  isClickableExternalChip() {
    return (
      this.typedElements.chip?.is &&
      this.typedElements.chip?.value?.clickable &&
      this.typedElements.chip?.value?.isAnExternalLink
    );
  }

  getClickableChipHref() {
    if (
      this.typedElements.chip?.is &&
      this.typedElements.chip?.value?.clickable
    ) {
      return this.typedElements.chip?.value?.href;
    }

    return undefined;
  }

  getLinkHref(withoutTelAndMailto = false) {
    if (
      this.typedElements.link?.is &&
      this.typedElements.link?.value &&
      (this.typedElements.link?.value?.linkType === 'internal' ||
        this.typedElements.link?.value?.linkType === 'external')
    ) {
      const link = this.typedElements.link?.value?.href;

      if (withoutTelAndMailto) {
        if (link.includes('mailto:', 0)) return link.substring(7);
        if (link.includes('tel:', 0)) return link.substring(4);

        return link;
      }

      return link;
    }

    return undefined;
  }

  expansionPanelActionClicked(e: any, index: number) {
    const obj = {
      e,
      index,
    };
    this.expansionPanelActionElementClickAction.emit(obj);
  }

  onExpandableElementExpand() {
    this.subExpansionPanelExpand.emit();
  }
}
