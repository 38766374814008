<!-- Begin:: Lazy Loading Indicator -->
<div
  *ngIf="item?.lazy || item?.loading; else activeElement"
  attr.data-testid="dlt_element_{{
    item?.key?.toLowerCase()?.replace(' ', '_')
  }}_lazy_loading"
>
  <div class="content-types__attribute-loading"></div>
  <div class="content-types__attribute-value-loading placeholder-item"></div>
</div>
<!-- End:: Loading Indicator -->

<ng-template #activeElement>
  <div
    *ngIf="item?.key"
    [ngClass]="{
      'w-100': item?.fullWidthColumn,
      'pl-0': item?.fullWidthColumn,
      'dlt-single-element': !typedElements.chip.is
    }"
    [ngStyle]="{ 'font-weight': item?.valueFontWeight }"
  >
    <!-- Begin:: Title of the Element -->
    <div
      class="content-types__attribute"
      [ngClass]="{
        'chip-element-key-in-header-row': headerRow && typedElements.chip.is
      }"
      [ngStyle]="{
        color: item?.keyTextColor,
        'font-weight': item?.keyFontWeight
      }"
      attr.data-testid="dlt_item_title_{{
        item?.key?.toLowerCase()?.replace(' ', '_')
      }}"
    >
      {{ item?.key }}
    </div>
    <!-- End:: Title of the Element -->

    <!-- Begin:: Plain Element -->
    <div
      *ngIf="typedElements.plain?.is && typedElements.plain?.value?.value"
      class="content-types__attribute-value"
      [ngClass]="{
        'content-types__wrapped-text': panel?.expanded,
        'content-types__ellipsis-block': !panel?.expanded
      }"
      [ngStyle]="{ 'font-weight': item?.valueFontWeight }"
      attr.data-testid="dlt_item_{{
        item?.key?.toLowerCase()?.replace(' ', '_')
      }}_value_{{
        typedElements.plain.value?.value
          ?.toString()
          ?.toLowerCase()
          ?.replace(' ', '_')
      }}"
    >
      {{ typedElements.plain.value?.value }}
      <copy-chip
        *ngIf="!typedElements.plain?.value?.blockCopyOption"
        [contentToCopy]="
          typedElements.plain.value?.textToCopy ??
          typedElements.plain.value?.value
        "
      ></copy-chip>
    </div>
    <!-- End:: Plain Element -->

    <!-- Begin:: Link Element -->
    <div
      *ngIf="typedElements.link?.is"
      class="d-flex content-types__attribute-value"
      [ngClass]="{
        'content-types__ellipsis-block': !panel?.expanded
      }"
    >
      <!-- Begin:: External Link Element -->
      <div
        *ngIf="typedElements.link?.value?.linkType === 'external'"
        attr.data-testid="dlt_item_link_external_{{
          item?.key?.toLowerCase()?.replace(' ', '_')
        }}_value_{{
          typedElements.link.value?.value
            ?.toString()
            ?.toLowerCase()
            ?.replace(' ', '_')
        }}"
      >
        <a [href]="getLinkHref()" target="_blank" rel="noopener noreferrer">{{
          typedElements.link.value?.value
        }}</a>
      </div>
      <!-- End:: External Link Element -->

      <!-- Begin:: Internal Link Element -->
      <div
        *ngIf="typedElements.link?.value?.linkType === 'internal'"
        attr.data-testid="dlt_item_link_internal_{{
          item?.key?.toLowerCase()?.replace(' ', '_')
        }}_value_{{
          typedElements.link.value?.value
            ?.toString()
            ?.toLowerCase()
            ?.replace(' ', '_')
        }}"
      >
        <a class="content-types__special-link" (click)="navigateToLink()">{{
          typedElements.link.value?.value
        }}</a>
      </div>
      <!-- End:: Internal Link Element -->

      <!-- Begin:: Action Link Element -->
      <div
        *ngIf="typedElements.link?.value?.linkType === 'action'"
        attr.data-testid="dlt_item_action_link{{
          item?.key?.toLowerCase()?.replace(' ', '_')
        }}_value_{{
          typedElements.link.value?.value
            ?.toString()
            ?.toLowerCase()
            ?.replace(' ', '_')
        }}"
      >
        <a class="content-types__special-link" (click)="actionLinkTrigger()">{{
          typedElements.link.value?.value
        }}</a>
      </div>
      <!-- End:: Action Link Element -->
      <copy-chip
        *ngIf="
          !typedElements.link?.value?.blockCopyOption &&
          (typedElements.link?.value?.linkType === 'external' ||
            typedElements.link?.value?.linkType === 'internal')
        "
        [contentToCopy]="
          typedElements.link.value?.textToCopy ??
          typedElements.link.value?.value
        "
        attr.data-testid="dlt_item_action_link{{
          item?.key?.toLowerCase()?.replace(' ', '_')
        }}_value_{{
          typedElements.link.value?.value
            ?.toString()
            ?.toLowerCase()
            ?.replace(' ', '_')
        }}_copy_chip"
      ></copy-chip>
    </div>
    <!-- End:: Link Element -->

    <!-- Begin:: Chip Element -->
    <ng-container *ngIf="typedElements.chip?.is">
      <br />
      <!-- Begin:: Non-Clickable Chip -->
      <div *ngIf="!typedElements.chip?.value?.clickable">
        <mat-chip-list>
          <mat-chip
            [ngStyle]="{
              'background-color': typedElements.chip.value?.chipBackgroundColor,
              color: typedElements.chip.value?.chipColor
            }"
            attr.data-testid="dlt_item_non_clickable_chip_{{
              item?.key?.toLowerCase()?.replace(' ', '_')
            }}_value_{{
              typedElements.chip.value?.value
                ?.toString()
                ?.toLowerCase()
                ?.replace(' ', '_')
            }}"
          >
            {{ typedElements.chip.value?.value }}
          </mat-chip>
        </mat-chip-list>
      </div>
      <!-- End:: Non-Clickable Chip -->

      <!-- Begin:: Clickable Chip -->
      <div
        *ngIf="typedElements.chip?.value?.clickable"
        class="content-types__attribute-value"
      >
        <!-- Begin:: Internal Link -->
        <div *ngIf="isClickableExternalChip()">
          <a [href]="getClickableChipHref()">
            <mat-chip-list>
              <mat-chip
                [ngStyle]="{
                  'background-color':
                    typedElements.chip.value?.chipBackgroundColor,
                  color: typedElements.chip.value?.chipColor
                }"
                attr.data-testid="dlt_item_clickable_chip_internal_{{
                  item?.key?.toLowerCase()?.replace(' ', '_')
                }}_value_{{
                  typedElements.chip.value?.value
                    ?.toString()
                    ?.toLowerCase()
                    ?.replace(' ', '_')
                }}"
              >
                {{ typedElements.chip.value?.value }}
              </mat-chip>
            </mat-chip-list>
          </a>
        </div>
        <!-- End:: Internal Link -->

        <!-- Begin:: External Link -->
        <div *ngIf="!isClickableExternalChip()" (click)="navigateToLink()">
          <mat-chip-list>
            <mat-chip
              [ngStyle]="{
                'background-color':
                  typedElements.chip.value?.chipBackgroundColor,
                color: typedElements.chip.value?.chipColor
              }"
              attr.data-testid="dlt_item_clickable_chip_external_{{
                item?.key?.toLowerCase()?.replace(' ', '_')
              }}_value_{{
                typedElements.chip.value?.value
                  ?.toString()
                  ?.toLowerCase()
                  ?.replace(' ', '_')
              }}"
            >
              {{ typedElements.chip.value?.value }}
            </mat-chip>
          </mat-chip-list>
        </div>
        <!-- End:: External Link -->
      </div>
      <!-- End:: Clickable Chip -->
    </ng-container>
    <!-- End:: Chip Element -->

    <!-- Begin:: Inner Html Element -->
    <ng-container
      *ngIf="
        typedElements.inner_html?.is &&
        typedElements.inner_html?.value?.htmlString
      "
    >
      <div
        class="content-types__attribute-value"
        [innerHTML]="typedElements.inner_html.value?.htmlString"
      ></div>
      <copy-chip
        *ngIf="!typedElements.inner_html?.value?.blockCopyOption"
        [contentToCopy]="
          typedElements.inner_html.value?.textToCopy ??
          typedElements.inner_html.value?.htmlString
        "
      ></copy-chip>
    </ng-container>
    <!-- End:: Inner Html Element -->

    <!-- Begin:: Button Element -->
    <div
      *ngIf="typedElements.button?.is"
      class="content-types__attribute-value"
    >
      <button
        class="px-1"
        [ngStyle]="{
          'background-color': typedElements.button.value?.backgroundColor,
          color: typedElements.button.value?.textColor
        }"
        (click)="dataElementActionClicked()"
        mat-flat-button
        attr.data-testid="dlt_item_btn_{{
          item?.key?.toLowerCase()?.replace(' ', '_')
        }}_value_{{
          typedElements.button?.value?.face
            ?.toString()
            ?.toLowerCase()
            ?.replace(' ', '_')
        }}"
      >
        <mat-icon *ngIf="typedElements.button?.value?.icon" class="mr-2 ml-2">{{
          typedElements.button.value?.icon
        }}</mat-icon>
        <span *ngIf="typedElements.button?.value?.face" class="mr-2">{{
          typedElements.button.value?.face
        }}</span>
      </button>
    </div>
    <!-- End:: Button Element -->
  </div>
</ng-template>

<!-- Separator Element -->
<ng-container *ngIf="typedElements.separator?.is" class="row w-100 mx-0">
  <mat-divider
    class="separator-line"
    attr.data-testid="dlt_item_separator_{{
      item?.key?.toLowerCase()?.replace(' ', '_')
    }}_value_{{
      typedElements.separator?.value?.data
        ?.toString()
        ?.toLowerCase()
        ?.replace(' ', '_')
    }}"
  ></mat-divider>
  <span
    *ngIf="typedElements.separator?.value?.data"
    class="w-100 mx-0 separator"
  >
    <dlt-single-element
      [item]="typedElements.separator.value?.data"
    ></dlt-single-element>
  </span>
</ng-container>
<!-- End:: Separator Element -->

<!-- ProgressBar Element -->
<ng-container *ngIf="typedElements.progress_bar?.is">
  <div
    *ngIf="
      typedElements.progress_bar?.is && typedElements.progress_bar?.value?.value
    "
    class="content-types__attribute-value"
    [ngClass]="{
      'content-types__wrapped-text': panel?.expanded,
      'content-types__ellipsis-block': !panel?.expanded
    }"
    [ngStyle]="{ 'font-weight': item?.valueFontWeight }"
  >
    {{ typedElements.progress_bar.value?.value }}
  </div>
  <br />
  <div class="progress-linear w-75">
    <div
      class="bar-active"
      [ngStyle]="{
        'width.%': typedElements.progress_bar.value?.progress,
        'background-color': progressBarColor
      }"
    ></div>
  </div>
</ng-container>
<!-- End:: ProgressBar Element -->

<!-- Expandable Element -->
<ng-container *ngIf="typedElements.expansion_panel?.is">
  <mat-expansion-panel style="border: 0">
    <mat-expansion-panel-header class="expansion-panel-header">
      <mat-panel-title class="col-md-6">
        <dlt-single-element
          [panel]="panel"
          [item]="typedElements.expansion_panel.value?.title"
        ></dlt-single-element>
      </mat-panel-title>
      <mat-panel-description class="col-md-6">
        <div *ngIf="typedElements.expansion_panel.value?.chip">
          <mat-chip-list>
            <mat-chip
              [ngStyle]="{
                'background-color':
                  typedElements.expansion_panel.value?.chip
                    ?.chipBackgroundColor,
                color: typedElements.expansion_panel.value?.chip?.chipColor
              }"
              attr.data-testid="dlt_item_non_clickable_chip_{{
                item?.key?.toLowerCase()?.replace(' ', '_')
              }}_value_{{
                typedElements.chip.value?.value
                  ?.toString()
                  ?.toLowerCase()
                  ?.replace(' ', '_')
              }}"
            >
              {{ typedElements.expansion_panel.value?.chip?.value }}
            </mat-chip>
          </mat-chip-list>
        </div>
      </mat-panel-description>
      <!-- Add this line for the expand icon -->
    </mat-expansion-panel-header>
    <div class="grid-container">
      <div
        *ngFor="
          let item of typedElements.expansion_panel.value?.content;
          let i = index
        "
        class="grid-item"
      >
        <dlt-single-element
          [panel]="panel"
          [item]="item"
          [panel]="panel"
          [item]="item"
          (onDataElementClickAction)="
            expansionPanelActionClicked($event, i + 2)
          "
          (subExpansionPanelExpand)="onExpandableElementExpand()"
        ></dlt-single-element>
      </div>
    </div>
  </mat-expansion-panel>
</ng-container>
<!-- End:: Expandable Element -->
